import React, { useRef, useState } from 'react';
import './Slider.css';  // Ensure the CSS is imported
import Modal from './components/Modal';
import Lottie from 'lottie-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrayingHands } from '@fortawesome/free-solid-svg-icons';
import animationData from './fly.json';
const Slider = () => {

    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const aboutRef = useRef(null);

    const handleKnowMoreClick = () => {
        if (aboutRef.current) {
            aboutRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <div className="flex min-h-screen w-screen" style={{ fontFamily: 'Source Sans Pro, sans-serif' }}>
                <div className="relative my-auto mx-auto flex flex-col px-4 sm:max-w-xl md:max-w-screen-xl md:flex-row">
                    {/* Left Column */}
                    <div className="mx-auto flex w-full max-w-xl lg:max-w-screen-xl">
                        <div className="mb-16 lg:my-auto lg:max-w-lg">
                            <div className="mb-6 max-w-xl">
                                <div>
                                    <p className="bg-teal-accent-400 mb-2 inline-block rounded-full bg-lime-300 px-3 py-1 text-2x font-semibold uppercase tracking-wider text-indigo-900">
                                        <FontAwesomeIcon icon={faPrayingHands} className="mr-2" size="2x" />
                                        Welcome
                                    </p>
                                </div>
                                <h2 className="mb-6 max-w-lg text-3xl font-extrabold text-slate-700 sm:text-5xl sm:leading-snug">
                                    Discover Exciting Careers in <br/>
                                    Aviation, Logistics and Tourism
                                    <span
                                        className="rounded- abg-gradient-to-r inline-block bg-orange-400 from-lime-400 to-sky-400 px-2 font-bold text-white"> Great-I Pvt Ltd. </span>
                                </h2>
                                <p className="text-base text-gray-700 md:text-lg">Explore the dynamic world of aviation
                                    and logistics with Great I’s comprehensive training programs. Our range of courses, including Airport Management, Ground Staff Training, Cabin Crew Training, Cargo Handling, and Airline Marketing, are designed to equip you with the skills needed for a successful career in these fast-paced industries. We also offer specialized disciplines and market research training, ensuring you gain both theoretical knowledge and practical expertise. Join us to excel in aviation and logistics, and fuel your career with the insights and training provided by our experienced professionals. </p>
                            </div>
                            <div className="flex items-center">
                                <button
                                    onClick={handleKnowMoreClick}
                                    className="bg-sky-400a mr-6 inline-flex h-12 items-center justify-center rounded-full bg-gradient-to-r from-lime-400 to-sky-400 px-8 font-medium tracking-wide text-white shadow-lg shadow-sky-300 outline-none transition duration-200 hover:scale-110 hover:bg-sky-500 focus:ring"
                                >
                                    Know More
                                </button>
                                <a href="/about"
                                   className="inline-flex items-center font-semibold text-sky-400 transition-colors duration-200 hover:text-lime-400">
                                    Our Courses
                                </a>

                                <div>
                                    <Lottie
                                        animationData={animationData}
                                        loop={true}
                                        autoplay={true}
                                        style={{height: 300, width: 300}}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* /Left Column */}

                    {/* Right Column */}
                    <div className="flex h-full w-full space-x-3 overflow-hidden md:justify-end">
                        {/* Col 2 */}
                        <div className="hidden w-56 items-center space-y-3 lg:flex">
                            <div className="overflow-hidden rounded-xl bg-yellow-400">
                                <img className="h-full w-full object-cover" src="/images/slide1.png" alt="Slide 1" />
                            </div>
                        </div>
                        <div className="w-full flex-col space-y-3 rounded-xl py-4 lg:flex lg:w-80">
                            <div className="h-40 overflow-hidden rounded-xl bg-green-600/60">
                                <img className="mx-auto h-full w-full object-cover" src="/images/slide2.jpg" alt="Slide 2" />
                            </div>
                            <div className="h-40 overflow-hidden rounded-xl bg-pink-600/60">
                                <img className="mx-auto h-full w-full object-cover" src="/images/slide3.jpg" alt="Slide 3" />
                            </div>
                            <div className="h-40 overflow-hidden rounded-xl bg-blue-600/60">
                                <img className="mx-auto h-full w-full object-cover" src="/images/slide4.jpg" alt="Slide 4" />
                            </div>
                        </div>
                    </div>
                    {/* /Right Column */}
                </div>
            </div>
            <section id="about-us" ref={aboutRef} className="bg-gray-900 dark:bg-gray-900">
                <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
                    <div className="mr-auto place-self-center lg:col-span-7">
                        <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">About us </h1>
                        <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">GRANDES ROUTE EDNBIZ AVIATION AND TOURISM INNOVATION PRIVATE LIMITED, where
                            we are committed to transforming education in the aviation and logistics sectors. As a dynamic
                            startup, we are passionate about empowering individuals with the skills and knowledge needed
                            to thrive in these fast-paced industries. Our innovative approach combines practical training with
                            industry insights, ensuring our students are well-prepared for the challenges and opportunities. </p>

                        <button
                            onClick={handleOpenModal}
                            className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white-900 border border-gray-300 rounded-lg hover:bg-orange-500 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-orange-500 dark:focus:ring-gray-800"
                        >
                            Free Consultation
                        </button>
                    </div>
                    <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
                        <img src="/images/about.jpg" alt="Training"/>
                    </div>
                </div>
                <Modal show={showModal} onClose={handleCloseModal} />
            </section>
        </>
    );
}

export default Slider;
