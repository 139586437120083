import React, { useEffect } from 'react';

const Modal = ({ show, onClose }) => {
    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data === 'formSubmitted') {
                onClose();
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [onClose]);

    useEffect(() => {
        if (show) {
            const iframe = document.querySelector('iframe');
            const style = document.createElement('style');
            style.innerHTML = `
                #footer > .thankyou-footer-wrapper {
                    display: none !important;
                }
            `;
            iframe.contentWindow.document.head.appendChild(style);
        }
    }, [show]);

    if (!show) {
        return null;
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-8 rounded-md shadow-lg w-full max-w-md mx-2 relative">
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded-md"
                >
                    Close
                </button>
                <div style={{ position: 'relative', width: '100%', height: '500px' }}>
                    <iframe
                        title="JotForm"
                        src="https://form.jotform.com/242070931561451"
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        style={{ border: "none" }}
                        allowFullScreen
                    ></iframe>
                    <div style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: '56px',
                        backgroundColor: '#FFFFFF'  // or whatever the background color of your modal is
                    }}></div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
